import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import "../styles/recommendations.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

  <Layout mdxType="Layout">
    <SEO title="👍 Recommendations" mdxType="SEO" />
    <h1>Our Recommendations</h1>
    <hr className="line" />
    <div className="recommendations">
Here are some of the books and podcasts we recommend. They're divided up first by topic, and then by medium (books / podcasts / blogs). An asterisk (*) indicates a particular favourite. Drop us an email if you picked any of these up and enjoyed them!
        <h2 {...{
          "id": "️-philosophy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#%EF%B8%8F-philosophy",
            "aria-label": "️ philosophy permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`☁️ Philosophy`}</h2>
        <h3 {...{
          "id": "-books",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-books",
            "aria-label": " books permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📖 Books`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/50485582-the-precipice"
            }}>{`The Precipice`}</a>{` by Toby Ord *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/327051.Reasons_and_Persons"
            }}>{`Reasons and Persons`}</a>{` by Derek Parfit *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/24113.G_del_Escher_Bach"
            }}>{`Gödel, Escher, Bach: An Eternal Golden Braid `}</a>{` by Douglas R. Hofstadter *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/23398748-doing-good-better"
            }}>{`Doing Good Better`}</a>{` by William MacAskill`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/1705582.Natural_Justice"
            }}>{`Natural Justice`}</a>{` by Ken Binmore`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/29378.Practical_Ethics"
            }}>{`Practical Ethics`}</a>{` by Peter Singer`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/61539.The_Structure_of_Scientific_Revolutions"
            }}>{`Structure of Scientific Revolutions`}</a>{` by Thomas Kuhn`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/144960.The_Conscious_Mind"
            }}>{`The Conscious Mind`}</a>{` by David Chalmers`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/20527133-superintelligence"
            }}>{`Superintelligence`}</a>{` by Nick Bostrom`}</li>
        </ul>
        <h3 {...{
          "id": "-blogs",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-blogs",
            "aria-label": " blogs permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📝 Blogs`}</h3>
        <ul>
          <li parentName="ul">{`Eric Schwitzgebel's `}<a parentName="li" {...{
              "href": "http://schwitzsplinters.blogspot.com/"
            }}>{`The Splintered Mind`}</a></li>
          <li parentName="ul">{`Maria Popova's `}<a parentName="li" {...{
              "href": "https://www.brainpickings.org/"
            }}>{`Brain Pickings`}</a></li>
          <li parentName="ul">{`Scott Alexander's `}<a parentName="li" {...{
              "href": "https://astralcodexten.substack.com/"
            }}>{`Astral Codex Ten`}</a>{` (formerly `}<a parentName="li" {...{
              "href": "https://slatestarcodex.com/"
            }}>{`Slate Star Codex`}</a>{`)`}</li>
        </ul>
        <h3 {...{
          "id": "-podcasts",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-podcasts",
            "aria-label": " podcasts permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🎙 Podcasts`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://80000hours.org/"
            }}>{`80,000 Hours`}</a>{` *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://verybadwizards.fireside.fm/"
            }}>{`Very Bad Wizards`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://philosophizethis.org/"
            }}>{`Philosophize This`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://philosophybites.com/"
            }}>{`Philosophy Bites`}</a></li>
        </ul>
        <h2 {...{
          "id": "-economics",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#-economics",
            "aria-label": " economics permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`💸 Economics`}</h2>
        <h3 {...{
          "id": "-books-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-books-1",
            "aria-label": " books 1 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📖 Books`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/10245602-poor-economics"
            }}>{`Poor Economics`}</a>{` by Abhijit V. Banerjee and Esther Duflo *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/82120.The_Worldly_Philosophers"
            }}>{`Worldly Philosophers`}</a>{` by Robert L. Heilbroner`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/20702125-economics"
            }}>{`Economics: The User's Guide`}</a>{` by Ha-Joon Change`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/51014619-good-economics-for-hard-times"
            }}>{`Good Economics for Hard Times`}</a>{` by Abhijit V. Banerjee and Esther Duflo`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/12158480-why-nations-fail"
            }}>{`Why Nations Fail`}</a>{` by  Daron Acemoğlu and James A. Robinson`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/27311800-global-inequality?from_search=true&from_srp=true&qid=0kGMw3L7ng&rank=2"
            }}>{`Global Inequality`}</a>{` by Branko Milanović`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/36515770-radical-markets"
            }}>{`Radical Markets`}</a>{` by Eric A. Posner and E. Glen Weyl`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/3388277-mostly-harmless-econometrics"
            }}>{`Mostly Harmless Econometrics`}</a>{`  by Joshua D. Angrist and Jörn-Steffen Pischke`}</li>
        </ul>
        <h3 {...{
          "id": "-blogs-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-blogs-1",
            "aria-label": " blogs 1 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📝 Blogs`}</h3>
        <ul>
          <li parentName="ul">{`Kevin Bryan's `}<a parentName="li" {...{
              "href": "https://afinetheorem.wordpress.com/"
            }}>{`A Fine Theorem`}</a>{` *`}</li>
          <li parentName="ul">{`Noah Smith's `}<a parentName="li" {...{
              "href": "https://noahpinion.substack.com/"
            }}>{`Noahopinion`}</a>{` *`}</li>
          <li parentName="ul">{`Tyler Cowen and Alex Tabarrok's `}<a parentName="li" {...{
              "href": "https://marginalrevolution.com/"
            }}>{`Marginal Revolution`}</a></li>
          <li parentName="ul">{`Diane Coyle's `}<a parentName="li" {...{
              "href": "http://www.enlightenmenteconomics.com/blog/"
            }}>{`The Enlightened Economist`}</a></li>
          <li parentName="ul">{`Robin Hanson's `}<a parentName="li" {...{
              "href": "http://www.overcomingbias.com/"
            }}>{`Overcoming Bias`}</a></li>
        </ul>
        <h3 {...{
          "id": "-podcasts-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-podcasts-1",
            "aria-label": " podcasts 1 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🎙 Podcasts`}</h3>
        <ul>
          <li parentName="ul">{`Brad DeLong and Noah Smith's `}<a parentName="li" {...{
              "href": "https://podcasts.apple.com/us/podcast/hexapodia-is-the-key-insight-by-noah-smith-brad-delong/id1552990332"
            }}>{`Hexapodia`}</a>{` *`}</li>
          <li parentName="ul">{`Tyler Cowen's `}<a parentName="li" {...{
              "href": "https://conversationswithtyler.com/"
            }}>{`Conversations with Tyler`}</a>{` *`}</li>
          <li parentName="ul">{`Russ Roberts' `}<a parentName="li" {...{
              "href": "https://www.econtalk.org/"
            }}>{`Econ Talk`}</a></li>
        </ul>
        <h2 {...{
          "id": "🧠-social-sciences-and-psychology",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#%F0%9F%A7%A0-social-sciences-and-psychology",
            "aria-label": "🧠 social sciences and psychology permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🧠 Social Sciences and Psychology`}</h2>
        <h3 {...{
          "id": "-books-2",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-books-2",
            "aria-label": " books 2 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📖 Books`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/23995360-superforecasting"
            }}>{`Superforecasting`}</a>{` by  Philip E. Tetlock and Dan Gardner *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/35696171-enlightenment-now"
            }}>{`Enlightenment Now`}</a>{` by Steven Pinker *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/11324722-the-righteous-mind"
            }}>{`The Righetous Mind`}</a>{` by Jonathan Haidt`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/28820444-the-elephant-in-the-brain"
            }}>{`The Elephant in the Brain`}</a>{` by Kevin Simler and Robin Hanson`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/1842.Guns_Germs_and_Steel"
            }}>{`Guns, Germs and Steel`}</a>{` by Jared Diamond`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/350549.The_Passions_and_the_Interests"
            }}>{`The Passions and the Interests`}</a>{` by Albert Hirschman`}</li>
        </ul>
        <h2 {...{
          "id": "🧪-maths-and-science",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#%F0%9F%A7%AA-maths-and-science",
            "aria-label": "🧪 maths and science permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🧪 Maths and Science`}</h2>
        <h3 {...{
          "id": "-books-3",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-books-3",
            "aria-label": " books 3 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`📖 Books`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://brianchristian.org/the-alignment-problem/"
            }}>{`The Alignment Problem`}</a>{` by Brian Christian *`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/44767248-human-compatible"
            }}>{`Human Compatible: Artificial Intelligence and the Problem of Control `}</a>{` by Stuart Russell`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/61535.The_Selfish_Gene"
            }}>{`The Selfish Gene`}</a>{` by Richard Dawkins`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/31170723-behave"
            }}>{`Behave`}</a>{` by Robert M. Sapolsky`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/35604796-what-is-real"
            }}>{`What is Real?: The Unfinished Quest for the Meaning of Quantum Physics`}</a>{` by Adam Becker`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/31670196-scale"
            }}>{`Scale`}</a>{` by Geoffrey B. West`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/242472.The_Black_Swan"
            }}>{`The Black Swan: The Impact of the Highly Improbable`}</a>{` by Nassim Nicholas Taleb`}</li>
        </ul>
        <h3 {...{
          "id": "-podcasts-2",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-podcasts-2",
            "aria-label": " podcasts 2 permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🎙 Podcasts`}</h3>
        <ul>
          <li parentName="ul">{`Sean Carroll's `}<a parentName="li" {...{
              "href": "https://www.preposterousuniverse.com/podcast/"
            }}>{`Mindscape`}</a></li>
          <li parentName="ul">{`Lex Fridman's `}<a parentName="li" {...{
              "href": "https://lexfridman.com/ai/"
            }}>{`Artificial Intelligence`}</a></li>
        </ul>
        <h3 {...{
          "id": "-videos",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#-videos",
            "aria-label": " videos permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`🎥 Videos`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/channel/UCYO_jab_esuFRV4b17AJtAw"
            }}>{`3Blue1Brown`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/channel/UCKzJFdi57J53Vr_BkTfN3uQ"
            }}>{`Primer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/channel/UCbfYPyITQ-7l4upoX8nvctg"
            }}>{`Two Minute Papers`}</a></li>
        </ul>
        <h2 {...{
          "id": "-miscellaneous",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#-miscellaneous",
            "aria-label": " miscellaneous permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`👾 Miscellaneous`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.nytimes.com/column/ezra-klein-podcast"
            }}>{`Ezra Klein Show`}</a>{` 🎙`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/channel/UCNvsIonJdJ5E4EXMa65VYpA"
            }}>{`Contrapoints`}</a>{` 🎥`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/user/CGPGrey"
            }}>{`CGP Grey`}</a>{` 🎥`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/extracredits/"
            }}>{`Extra History`}</a>{` 🎥`}</li>
          <li parentName="ul">{`Dan Carlin's `}<a parentName="li" {...{
              "href": "https://www.dancarlin.com/hardcore-history-series/"
            }}>{`Hardcore History`}</a>{` 🎙`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.goodreads.com/book/show/25666050-algorithms-to-live-by"
            }}>{`Algorithms to Live By`}</a>{` by Brian Christian 📖`}</li>
        </ul>
        <h2 {...{
          "id": "-friends-of-the-podcast",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#-friends-of-the-podcast",
            "aria-label": " friends of the podcast permalink",
            "className": "anchor before"
          }}><undefined parentName="a">{`
              `}<svg {...{
                "aria-hidden": "true",
                "data-icon": "link",
                "height": "20",
                "viewBox": "0 0 512 512"
              }}><path parentName="svg" {...{
                  "d": "M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                }}></path></svg></undefined></a>{`😊 Friends of the Podcast`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://80000hours.org/podcast/"
            }}>{`80,000 Hours Podcast`}</a>{` 🎙`}</li>
          <li parentName="ul">{`Ben and Vaden's `}<a parentName="li" {...{
              "href": "https://www.incrementspodcast.com/"
            }}>{`Increments Podcast`}</a>{` 🎙`}</li>
          <li parentName="ul">{`Richard Fisher's `}<a parentName="li" {...{
              "href": "https://longtermist.substack.com/"
            }}>{`The Longtermist's Field Guide`}</a>{` 📝`}</li>
          <li parentName="ul">{`Isabelle Boemeke's `}<a parentName="li" {...{
              "href": "http://www.isodope.com/"
            }}>{`Isodope`}</a>{` 📝`}</li>
          <li parentName="ul">{`Luke Robert Mason's `}<a parentName="li" {...{
              "href": "https://futurespodcast.net/"
            }}>{`Futures Podcast`}</a>{` 🎙`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.exploring-economics.org/"
            }}>{`Exploring Economics`}</a>{` 📝`}</li>
          <li parentName="ul">{`Neel Nanda's `}<a parentName="li" {...{
              "href": "https://www.neelnanda.io/"
            }}>{`Blog`}</a>{` 📝`}</li>
        </ul>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      